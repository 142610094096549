var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"pa-5",attrs:{"rounded":"lg"}},[_c('v-card-subtitle',{staticClass:"d-flex align-center"},[_c('div',{staticClass:"page-title mr-5"},[_vm._v("課程教材")]),_c('v-btn',{staticClass:"px-6",attrs:{"color":"primary","depressed":"","small":"","disabled":_vm.isFetching},on:{"click":_vm.addTextBook}},[_vm._v("新增")])],1),_c('v-card-text',{staticClass:"my-5"},[_c('v-row',[_c('DraggableTable',{attrs:{"tableHeader":_vm.headers,"data":_vm.dataList},on:{"setOrder":_vm.setOrder,"dragTableAction":_vm.performAction},scopedSlots:_vm._u([{key:"textbookName",fn:function(ref){
var index = ref.index;
return [_c('v-text-field',{staticClass:"my-3",attrs:{"color":"indigo darken-4","persistent-placeholder":"","hide-details":"auto","outlined":"","required":true,"rules":[
              function (v) {
                return _vm.$validate.required(v) || '此位置不能留空'
              } ],"disabled":_vm.isFetching,"dense":true},model:{value:(_vm.dataList[index].name),callback:function ($$v) {_vm.$set(_vm.dataList[index], "name", $$v)},expression:"dataList[index].name"}})]}},{key:"textbookFile",fn:function(ref){
            var index = ref.index;
return [_c('FormButtonUpload',{attrs:{"uploadFile":_vm.dataList[index].file,"fileInputKey":("textbookUpload_" + index),"acceptFormat":'textBook',"maxFileSize":100},on:{"update:uploadFile":function($event){return _vm.$set(_vm.dataList[index], "file", $event)},"update:upload-file":function($event){return _vm.$set(_vm.dataList[index], "file", $event)}}})]}}],null,true)})],1),_c('div',{staticClass:"error-msg mt-5"},[_vm._v(_vm._s(_vm.errorMsg))])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }