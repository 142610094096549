<template>
  <v-container>
    <v-form ref="course-module-form" @submit.prevent="formSubmit">
      <v-row>
        <v-col cols="12" class="d-flex">
          <v-btn @click.prevent="$router.go(-1)" plain :disabled="isFetching">
            <v-icon>{{ icons.mdiChevronLeft }}</v-icon>
            <div>上一頁</div>
          </v-btn>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12">
          <v-card rounded="lg" class="pa-5">
            <v-card-subtitle>
              <div class="page-title">{{ $route.meta.title }}</div>
            </v-card-subtitle>
            <v-card-text class="my-5">
              <v-row>
                <v-col md="12" lg="6" cols="12">
                  <h3 class="mb-3">影片</h3>
                  <FormButtonUpload
                    :uploadFile.sync="videoFileName"
                    :showUploadFileName="false"
                    :showUploadPreviewImg="true"
                    :fileInputKey="'courseVideo'"
                    :acceptFormat="'video'"
                    :maxFileSize="1024"
                    :getFileObject="true"
                    :vimeoLink="videoUrl"
                    @getFileObj="getVimeoVideoFileObj"
                  ></FormButtonUpload>
                  <div class="error-msg mt-2">{{ uploadVideoError }}</div>
                </v-col>

                <v-col md="12" lg="6" cols="12">
                  <v-row>
                    <v-col cols="12">
                      <form-control
                        inputType="string"
                        v-model="title"
                        label="單元標題"
                        :required="true"
                        :disabled="isFetching"
                      />
                    </v-col>
                    <v-col cols="12">
                      <form-control
                        inputType="textarea"
                        v-model="description"
                        label="單元描述"
                        :disabled="isFetching"
                      />
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12">
          <TextBookTable
            :bookList.sync="textBook"
            :errorMsg="textBookUploadError"
            @getRemovedTextBook="
              data => {
                removedTextBookArr.push(data)
              }
            "
          ></TextBookTable>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12">
          <v-card rounded="lg" class="pa-5">
            <v-card-subtitle class="d-flex align-center">
              <div class="page-title mr-5">教材管理</div>
              <v-btn color="primary" class="px-6" depressed small :disabled="isFetching" @click="addSlides">新增</v-btn>
            </v-card-subtitle>
            <v-card-text class="my-5">
              <v-row>
                <v-col cols="12" class="px-0">
                  <Datatable
                    :table-headers="slideHeader"
                    :table-data="slides"
                    :disableSort="true"
                    :disable-pagination="true"
                    :disableFiltering="true"
                    :itemTotal="-1"
                    :disableFooter="true"
                    :showTopSlot="false"
                    :removeShadow="true"
                    @tableDeleteBtn="removeSlides"
                  >
                    <template v-slot:[`item.imageUpload`]="{ index }">
                      <FormButtonUpload
                        :uploadFile.sync="slides[index].image"
                        :fileInputKey="`videoSlide_${index}`"
                        :acceptFormat="'image'"
                      ></FormButtonUpload>
                    </template>

                    <template v-slot:[`item.timeCode`]="{ index }">
                      <div class="d-flex align-center flex-wrap">
                        <span class="mr-6"> {{ getSlideDurationText(index) }}</span>

                        <v-btn
                          color="#646A71"
                          class="px-4 white--text"
                          depressed
                          small
                          @click="editVideoSlideTime(null, index)"
                        >
                          修改
                        </v-btn>
                      </div>
                    </template>
                  </Datatable>
                </v-col>
              </v-row>

              <div class="error-msg mt-2">{{ slideError }}</div>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12">
          <v-btn color="primary white--text" depressed class="px-6" small :disabled="isFetching" type="submit">
            儲存
          </v-btn>
        </v-col>
      </v-row>

      <CustomDialog
        :isDeleteDialog="false"
        :dialog="openVideoSlideDialog"
        :text="'影片時間'"
        @close="updateVideoSlide"
        :eager="true"
      >
        <template v-slot>
          <v-form ref="timecode-form">
            <v-row class="mt-3">
              <h3>開始：</h3>
            </v-row>
            <v-row class="mt-3">
              <v-col md="4" cols="12">
                <form-control
                  inputType="select"
                  v-model="videoTime.startHour"
                  :options="startHourList"
                  label="時"
                  :required="true"
                  :disabled="isFetching"
                  :readonly="startHourList.length <= 1"
                  @selectChange="startTimeChange($event)"
                />
              </v-col>
              <v-col md="4" cols="12">
                <form-control
                  inputType="select"
                  v-model="videoTime.startMin"
                  label="分"
                  :options="startMinList"
                  :required="true"
                  :disabled="isFetching"
                  :readonly="startMinList.length <= 1"
                  @selectChange="startTimeChange($event)"
                />
              </v-col>
              <v-col md="4" cols="12">
                <form-control
                  inputType="select"
                  v-model="videoTime.startSec"
                  label="秒"
                  :options="startSecList"
                  :required="true"
                  :disabled="isFetching"
                />
              </v-col>
            </v-row>

            <v-row class="mt-3">
              <h3>結束：</h3>
            </v-row>
            <v-row class="mt-3">
              <v-col md="4" cols="12">
                <form-control
                  inputType="select"
                  v-model="videoTime.endHour"
                  label="時"
                  :options="endHourList"
                  :required="true"
                  :disabled="isFetching"
                  :readonly="endHourList.length <= 1"
                  @selectChange="endTimeChange($event)"
                />
              </v-col>
              <v-col md="4" cols="12">
                <form-control
                  inputType="select"
                  v-model="videoTime.endMin"
                  label="分"
                  :options="endMinList"
                  :required="true"
                  :disabled="isFetching"
                  :readonly="endMinList.length <= 1"
                  @selectChange="endTimeChange($event)"
                />
              </v-col>
              <v-col md="4" cols="12">
                <form-control
                  inputType="select"
                  v-model="videoTime.endSec"
                  label="秒"
                  :options="endSecList"
                  :required="true"
                  :disabled="isFetching"
                />
              </v-col>
            </v-row>
          </v-form>
        </template>
      </CustomDialog>
    </v-form>
  </v-container>
</template>
 
 <script>
import { mapActions, mapState } from 'vuex'
import FormControl from '@/components/form/FormControl'
import { mdiChevronLeft } from '@mdi/js'
import FormButtonUpload from '@/components/form/FormButtonUpload.vue'
import TextBookTable from '@/components/TextBookTable.vue'
import Datatable from '@/components/Datatable.vue'
import CustomDialog from '@/components/CustomDialog.vue'
import VimeoMixin from '@/mixins/VimeoMixin.vue'

export default {
  name: 'OnlineCourseModule',
  components: {
    FormControl,
    FormButtonUpload,
    TextBookTable,
    Datatable,
    CustomDialog,
  },
  mixins: [VimeoMixin],
  setup() {
    return {
      icons: {
        mdiChevronLeft,
      },
      slideHeader: [
        { text: '圖片', value: 'imageUpload' },
        { text: '影片時間', value: 'timeCode' },
        { text: '', value: 'deleteBtn' },
      ],
      materialDialog: false,
    }
  },
  data() {
    return {
      submitSuccess: false,

      title: '',
      description: '',

      videoFileName: '', // for file upload to innpression academy server
      vimeoVideoID: '', // for video upload to vimeo
      saveVimeoVideoID: '',
      videoUrl: '', // for video uploadded to vimeo
      embedHTML: '',
      uploadVideoError: '',
      videoDuration: 0,
      fileObj: null,

      textBook: [],
      removedTextBookArr: [],
      textBookUploadError: '',

      slides: [],
      removedSlidesArr: [],
      openVideoSlideDialog: false,
      videoSlideIndex: -1,
      videoTime: {
        startHour: '',
        startMin: '',
        startSec: '',
        endHour: '',
        endMin: '',
        endSec: '',
      },
      totalTime: {
        hour: 0,
        min: 0,
        sec: 0,
      },
      startHourList: [],
      startMinList: [],
      startSecList: [],
      endHourList: [],
      endMinList: [],
      endSecList: [],
      slideError: '',

      updateFileObjCount: 0,
      submitFileObjCount: 0,
    }
  },
  computed: {
    ...mapState({
      isFetching: state => state.request.isFetching,
    }),

    courseID: function () {
      return this.$route.params.course_id
    },

    videoID: function () {
      return this.$route.params.video_id
    },

    disableUploadSlide: function () {
      return (
        !this.$validate.DataValid(this.videoFileName) &&
        !this.$validate.DataValid(this.vimeoVideoID) &&
        !this.$validate.DataValid(this.videoUrl) &&
        this.fileObj === null
      )
    },
  },
  methods: {
    ...mapActions(['setFetching'], 'request'),
    ...mapActions(['setDialogMessage', 'setShowDialog']),

    async getCourseDetail() {
      let courseError = false
      let message = '資料不存在'

      try {
        const payload = {
          id: parseInt(this.courseID),
          verify_token: this.getAdminToken(),
          get_course_only: true,
          course_mode: 'online',
        }

        const result = await this.$XHR.api('cms_get_course_by_id', payload)
        this.$func.log('-----Get Course Detail-----')
        this.$func.log(result)

        if (this.$validate.DataValid(this.videoID)) {
          this.getVideoDetail()
        } else {
          this.$store.dispatch('toggleLoadingPage', false)
        }
      } catch (error) {
        this.$func.log('-----Get Course Fail-----')
        this.$func.log(error)

        if (error.data === 'admin verification fail') {
          this.forceLogout()
        } else if (error.data === 'no permission') {
          courseError = true
          message = '沒有權限'
        } else {
          courseError = true
        }
      } finally {
        this.setFetching(false)
      }

      if (courseError) {
        this.$store.dispatch('toggleAlertMessage', {
          show: true,
          message,
          type: 'error',
          refresh: false,
          redirect: 'onlineCourse',
        })
      }
    },

    async getVideoDetail() {
      let videoError = false
      let message = '資料不存在'

      try {
        const payload = {
          id: parseInt(this.videoID),
          course_id: parseInt(this.courseID),
          verify_token: this.getAdminToken(),
        }

        const result = await this.$XHR.api('cms_get_video_by_id', payload)
        this.$func.log('-----Get Video Detail-----')
        this.$func.log(result)

        this.title = result.video_title
        this.description = result.video_description
        this.videoFileName = result.video_file
        this.vimeoVideoID = result.video_id
        this.saveVimeoVideoID = result.video_id
        this.videoUrl = result.video_url
        this.embedHTML = result.embed_html
        this.videoDuration = result.duration
        this.textBook = result.textbook
        this.slides = result.slides

        this.initializeHrMinSecList()

        this.$store.dispatch('toggleLoadingPage', false)
      } catch (error) {
        this.$func.log('-----Get Video Fail-----')
        this.$func.log(error)

        if (error.data === 'admin verification fail') {
          this.forceLogout()
        } else if (error.data === 'no permission') {
          videoError = true
          message = '沒有權限'
        } else {
          videoError = true
        }
      } finally {
        this.setFetching(false)
      }

      if (videoError) {
        this.$store.dispatch('toggleAlertMessage', {
          show: true,
          message,
          type: 'error',
          refresh: false,
          redirect: 'onlineCourse',
        })
      }
    },

    // for upload video
    initializeHrMinSecList() {
      const timeList = this.$func.translateToHourMinSec(this.videoDuration)

      this.totalTime = timeList

      this.startHourList = []
      this.startMinList = []
      this.startSecList = []
      this.endHourList = []
      this.endMinList = []
      this.endSecList = []

      for (let i = 0; i <= timeList.hour; i++) {
        this.startHourList.push({ text: i, value: i })
        this.endHourList.push({ text: i, value: i })
      }

      let minuteMax = timeList.hour > 0 ? 59 : timeList.min
      for (let i = 0; i <= minuteMax; i++) {
        this.startMinList.push({ text: i, value: i })
        this.endMinList.push({ text: i, value: i })
      }

      let secMax = timeList.min > 0 ? 59 : timeList.sec
      for (let i = 0; i <= secMax; i++) {
        this.startSecList.push({ text: i, value: i })
        this.endSecList.push({ text: i, value: i })
      }
    },

    async getVimeoVideoFileObj(fileObj) {
      this.fileObj = fileObj
      this.updateFileObjCount++
      this.videoDuration = parseInt(await this.getVideoDuration())

      // for select slide time
      this.initializeHrMinSecList()
    },

    getVideoDuration() {
      const url = URL.createObjectURL(this.fileObj)

      return new Promise(resolve => {
        const audio = document.createElement('audio')
        audio.muted = true
        const source = document.createElement('source')
        source.src = url //--> blob URL
        audio.preload = 'metadata'
        audio.appendChild(source)
        audio.onloadedmetadata = function () {
          resolve(audio.duration)
        }
      })
    },

    // for slides
    addSlides() {
      if (this.disableUploadSlide) {
        this.$store.dispatch('toggleAlertMessage', {
          show: true,
          message: '請先上傳影片',
          type: 'error',
          refresh: false,
          redirect: '',
        })
      } else {
        this.slides.push({
          image: '',
          time_code: '',
          slide_duration: '',
        })
      }
    },

    removeSlides(action, index) {
      if (action.value) {
        if (this.$validate.DataValid(this.slides[index].image)) {
          this.removedSlidesArr.push(this.slides[index].image)
        }
        this.slides.splice(index, 1)
      }

      action.value = false
    },

    getSlideDurationText(index) {
      if (this.$validate.DataValid(this.slides[index].time_code)) {
        const start = this.$func.translateToHourMinSec(this.slides[index].time_code)
        const end = this.$func.translateToHourMinSec(this.slides[index].time_code + this.slides[index].slide_duration)

        let startTime = `${start.hour > 0 ? start.hour + ':' : ''}${start.min >= 10 ? start.min : '0' + start.min}:${
          start.sec >= 10 ? start.sec : '0' + start.sec
        }`
        let endTime = `${end.hour > 0 ? end.hour + ':' : ''}${end.min >= 10 ? end.min : '0' + end.min}:${
          end.sec >= 10 ? end.sec : '0' + end.sec
        }`

        return `${startTime} 至 ${endTime}`
      } else {
        return ''
      }
    },

    editVideoSlideTime(event = null, index) {
      if (this.$validate.DataValid(this.slides[index].time_code)) {
        const start = this.$func.translateToHourMinSec(this.slides[index].time_code)
        this.$set(this.videoTime, 'startHour', start.hour)
        this.$set(this.videoTime, 'startMin', start.min)
        this.$set(this.videoTime, 'startSec', start.sec)

        const end = this.$func.translateToHourMinSec(this.slides[index].time_code + this.slides[index].slide_duration)
        this.$set(this.videoTime, 'endHour', end.hour)
        this.$set(this.videoTime, 'endMin', end.min)
        this.$set(this.videoTime, 'endSec', end.sec)
      } else {
        this.$set(this.videoTime, 'startHour', 0)
        this.$set(this.videoTime, 'startMin', 0)
        this.$set(this.videoTime, 'startSec', 0)
        this.$set(this.videoTime, 'endHour', 0)
        this.$set(this.videoTime, 'endMin', 0)
        this.$set(this.videoTime, 'endSec', 0)
      }

      this.updateStartTimeSelectList()
      this.updateEndTimeSelectList()

      this.videoSlideIndex = index
      this.openVideoSlideDialog = true
    },

    updateStartTimeSelectList() {
      // min
      if (this.totalTime.hour !== 0) {
        this.startMinList = []
        let minuteMax = 0

        if (this.totalTime.hour !== this.videoTime.startHour) {
          minuteMax = 59
        } else {
          minuteMax = this.totalTime.min

          if (this.videoTime.startMin > minuteMax) {
            this.$set(this.videoTime, 'startMin', 0)
          }
        }

        for (let i = 0; i <= minuteMax; i++) {
          this.startMinList.push({ text: i, value: i })
        }
      }

      // sec
      if (this.totalTime.hour !== 0 || this.totalTime.min !== 0) {
        this.startSecList = []
        let secMax = 0

        if (this.totalTime.hour === this.videoTime.startHour) {
          if (this.totalTime.min === this.videoTime.startMin) {
            secMax = this.totalTime.sec
            this.$set(this.videoTime, 'startSec', 0)
          } else {
            secMax = 59
          }
        } else {
          secMax = 59
        }

        if (this.videoTime.startSec > secMax) {
          this.$set(this.videoTime, 'startSec', 0)
        }

        for (let i = 0; i <= secMax; i++) {
          this.startSecList.push({ text: i, value: i })
        }
      }
    },

    updateEndTimeSelectList() {
      // min
      if (this.totalTime.hour !== 0) {
        this.endMinList = []
        let minuteMax = 0

        if (this.totalTime.hour !== this.videoTime.endHour) {
          minuteMax = 59
        } else {
          minuteMax = this.totalTime.min

          if (this.videoTime.endMin > minuteMax) {
            this.$set(this.videoTime, 'endMin', 0)
          }
        }

        for (let i = 0; i <= minuteMax; i++) {
          this.endMinList.push({ text: i, value: i })
        }
      }

      // sec
      if (this.totalTime.hour !== 0 || this.totalTime.min !== 0) {
        this.endSecList = []
        let secMax = 0

        if (this.totalTime.hour === this.videoTime.endHour) {
          if (this.totalTime.min === this.videoTime.endMin) {
            secMax = this.totalTime.sec
            this.$set(this.videoTime, 'endSec', 0)
          } else {
            secMax = 59
          }
        } else {
          secMax = 59
        }

        if (this.videoTime.endSec > secMax) {
          this.$set(this.videoTime, 'endSec', 0)
        }

        for (let i = 0; i <= secMax; i++) {
          this.endSecList.push({ text: i, value: i })
        }
      }
    },

    startTimeChange(event) {
      this.updateStartTimeSelectList()
    },

    endTimeChange(event) {
      this.updateEndTimeSelectList()
    },

    updateVideoSlide(action) {
      if (action) {
        // checking
        const validate = this.$refs['timecode-form'].validate()

        if (!validate) {
          this.$store.dispatch('toggleAlertMessage', {
            show: true,
            message: '請選擇開始與結束時間',
            type: 'error',
            refresh: false,
            redirect: '',
          })
          return
        }

        const startHour = parseInt(this.videoTime.startHour) * 60 * 60
        const startMin = parseInt(this.videoTime.startMin) * 60
        const startSec = parseInt(this.videoTime.startSec)

        const startTimeCode = startHour + startMin + startSec

        const endHour = parseInt(this.videoTime.endHour) * 60 * 60
        const endMin = parseInt(this.videoTime.endMin) * 60
        const endSec = parseInt(this.videoTime.endSec)

        const endTimeCode = endHour + endMin + endSec

        let errorMsg = ''

        if (startTimeCode > endTimeCode) {
          // check whether the start time is earlier than end time
          errorMsg = '開始時間不可早於結束時間'
        } else {
          for (let i = 0; i < this.slides.length; i++) {
            if (i === this.videoSlideIndex) {
              continue
            }
            const slideStart = parseInt(this.slides[i].time_code)
            const slideEnd = slideStart + parseInt(this.slides[i].slide_duration)

            if (this.checkTimeCodeOverlap(startTimeCode, endTimeCode, slideStart, slideEnd)) {
              errorMsg = '影片時間不可重疊'
              break
            }
          }
        }

        if (this.$validate.DataValid(errorMsg)) {
          this.$store.dispatch('toggleAlertMessage', {
            show: true,
            message: errorMsg,
            type: 'error',
            refresh: false,
            redirect: '',
          })

          return
        }

        this.$set(this.slides[this.videoSlideIndex], 'time_code', startTimeCode)
        this.$set(this.slides[this.videoSlideIndex], 'slide_duration', endTimeCode - startTimeCode)

        // reorder the slides
        this.reorderSlide()

        this.$forceUpdate()
      }

      this.openVideoSlideDialog = false
    },

    checkTimeCodeOverlap(startTime, endTime, compareStartTime, compareEndTime) {
      startTime = parseInt(startTime)
      endTime = parseInt(endTime)
      compareStartTime = parseInt(compareStartTime)
      compareEndTime = parseInt(compareEndTime)

      let overlap = false

      if (startTime === compareStartTime) {
        overlap = true
      } else if (endTime === compareEndTime) {
        overlap = true
      } else if (startTime <= compareStartTime && endTime > compareStartTime) {
        overlap = true
      } else if (startTime >= compareStartTime && startTime < compareEndTime) {
        overlap = true
      } else if (startTime <= compareStartTime && endTime >= compareEndTime) {
        overlap = true
      } else if (startTime >= compareStartTime && endTime <= compareEndTime) {
        overlap = true
      }

      return overlap
    },

    reorderSlide() {
      const filterArr = []
      const emptyTimeCodeArr = []

      for (let i = 0; i < this.slides.length; i++) {
        if (this.$validate.DataValid(this.slides[i].time_code)) {
          filterArr.push(this.slides[i])
        } else {
          emptyTimeCodeArr.push(this.slides[i])
        }
      }

      if (filterArr.length > 0) {
        filterArr.sort(function (a, b) {
          return a.time_code - b.time_code
        })

        this.slides = filterArr

        this.slides = this.slides.concat(emptyTimeCodeArr)
      }
    },

    validateVideoForm() {
      const isValid = this.$refs['course-module-form'].validate()

      this.uploadVideoError = ''
      this.textBookUploadError = ''
      this.slideError = ''

      if (this.disableUploadSlide) {
        this.uploadVideoError = '請上傳影片'
      }

      for (let i = 0; i < this.textBook.length; i++) {
        if (!this.$validate.DataValid(this.textBook[i].file)) {
          this.textBookUploadError = '請上傳檔案'
          break
        }
      }

      if (this.slides.length > 0) {
        let fileError = false
        let timeCodeError = false
        for (let i = 0; i < this.slides.length; i++) {
          if (!this.$validate.DataValid(this.slides[i].image)) {
            fileError = true
          }

          if (
            !this.$validate.DataValid(this.slides[i].time_code) ||
            !this.$validate.DataValid(this.slides[i].slide_duration)
          ) {
            timeCodeError = true
          }

          if (fileError && timeCodeError) {
            break
          }
        }

        let errorMsg = []
        if (fileError) {
          errorMsg.push('上傳圖片')
        }
        if (timeCodeError) {
          errorMsg.push('選擇影片時間')
        }

        if (errorMsg.length > 0) {
          this.slideError = `請${errorMsg.join('和')}`
        } else {
          let lastSlide = this.slides[this.slides.length - 1]
          if (lastSlide.time_code + lastSlide.slide_duration > this.videoDuration) {
            this.slideError = `教材出現時間不可超出影片總長度`
          }
        }
      }

      if (
        !isValid ||
        this.disableUploadSlide ||
        this.uploadVideoError !== '' ||
        this.slideError !== '' ||
        this.textBookUploadError !== ''
      ) {
        this.$store.dispatch('toggleAlertMessage', {
          show: true,
          message: '請先填滿所有必填項目',
          type: 'error',
          refresh: false,
          redirect: '',
        })
        this.setFetching(false)
        return false
      }

      return true
    },

    async formSubmit() {
      if (!this.isFetching) {
        this.setFetching(true)

        if (!this.validateVideoForm()) {
          return
        }

        this.$store.dispatch('toggleLoadingPage', true)

        let isAdd = !this.$validate.DataValid(this.videoID)

        try {
          if (this.updateFileObjCount !== 0) {
            if (this.updateFileObjCount !== this.submitFileObjCount) {
              // create new vimeo link
              const getVimeoLink = await this.createVimeoVideoLink(this.fileObj)
              let uploadVideoLink = getVimeoLink['data']['upload_link']
              let uploadVideoID = getVimeoLink['data']['video_id']
              this.$store.dispatch('insertRemovedVimeoVideoID', uploadVideoID)

              // upload video to the created link
              const uploadVideo = await this.uploadVimeoVideo(this.fileObj, uploadVideoLink)
              this.vimeoVideoID = uploadVideoID
            }

            this.submitFileObjCount = this.updateFileObjCount
          }

          const payload = {
            verify_token: this.getAdminToken(),
            data_type: 'video_data',
            data_status: 'active',
            status: 'active',
            course_id: parseInt(this.courseID),
            video_title: this.title,
            video_description: this.description,
            video_file: this.videoFileName,
            video_id: this.vimeoVideoID,
            video_url: '',
            embed_html: '',
            duration: parseInt(this.videoDuration),
            slides: this.slides,
            textbook: this.textBook,
          }

          let key = ''
          if (isAdd) {
            key = 'cms_add_video'
            payload.video_order = 0
          } else {
            payload.id = parseInt(this.videoID)
            key = 'cms_update_video'
          }

          const result = await this.$XHR.api(key, payload)
          this.$func.log('-----Save Video Data Success-----')
          this.$func.log(result)

          this.submitSuccess = true

          this.setDialogMessage({
            message: '儲存成功',
            isError: false,
            returnLink: { name: 'onlineCourseDetail', params: { id: this.courseID } },
          })

          this.setShowDialog(true)

          this.$store.dispatch('toggleLoadingPage', false)
        } catch (error) {
          this.$func.log('-----Save Video Data Fail-----')
          this.$func.log(error)

          this.submitSuccess = false

          if (error.data === 'admin verification fail') {
            this.forceLogout()
          } else {
            let msg = '儲存失敗'

            switch (error.data) {
              case 'missing upload file':
                msg = '沒有檔案可供上傳'
                break
              case 'create video link fail':
                msg = '上傳連結製作失敗'
                break
              case 'course does not exist':
                msg = '課程不存在'
                break
              case 'video not found':
                msg = '影片不存在'
            }

            this.setDialogMessage({
              message: msg,
              isError: false,
              returnLink: null,
            })
            this.setShowDialog(true)
          }

          this.$store.dispatch('toggleLoadingPage', false)
        } finally {
          this.setFetching(false)
        }
      }
    },
  },
  async created() {
    this.$store.dispatch('toggleLoadingPage', true)
    const check = await this.checkUserData()
    if (check) {
      this.setFetching(true)
      this.getCourseDetail()
    }
  },
  async beforeDestroy() {
    let removeList = []
    if (this.$store.getters.removedImageList.length > 0) {
      removeList = removeList.concat(this.$store.getters.removedImageList)
    }

    let removeVideoID = []
    if (this.$store.getters.removedVimeoVideoIDList.length > 0) {
      removeVideoID = removeVideoID.concat(this.$store.getters.removedVimeoVideoIDList)
    }

    if (this.submitSuccess) {
      removeList = removeList.concat(this.removedTextBookArr)
      removeList = removeList.concat(this.removedSlidesArr)

      // don't remove saved image
      const textBookFile = this.textBook.map(obj => {
        return obj.file
      })

      const slidesFile = this.slides.map(obj => {
        return obj.image
      })

      for (let i = 0; i < textBookFile.length; i++) {
        const index = removeList.indexOf(textBookFile[i])

        if (index !== -1) {
          removeList.splice(index, 1)
        }
      }

      for (let i = 0; i < slidesFile.length; i++) {
        const index = removeList.indexOf(slidesFile[i])

        if (index !== -1) {
          removeList.splice(index, 1)
        }
      }

      // don't remove saved video
      const saveVideoIndex = removeVideoID.indexOf(this.vimeoVideoID)
      if (saveVideoIndex !== -1) {
        removeVideoID.splice(saveVideoIndex, 1)
      }

      if (this.vimeoVideoID !== this.saveVimeoVideoID) {
        removeVideoID.push(this.saveVimeoVideoID)
      }
    } else {
      // don't remove the origin video
      const saveVideoIndex = removeVideoID.indexOf(this.saveVimeoVideoID)
      if (saveVideoIndex !== -1) {
        removeVideoID.splice(saveVideoIndex, 1)
      }
    }

    if (removeList.length > 0) {
      const removeImage = await this.$XHR.api('cms_remove_media', {
        verify_token: this.getAdminToken(),
        remove_image_list: removeList,
      })

      this.$func.log('--- remove image success ---')
      this.$func.log(removeImage)
    }

    this.$store.dispatch('resetRemovedImage', [])

    if (removeVideoID.length > 0) {
      const removeVimeoVideo = await this.$XHR.api('cms_delete_vimeo_video_list', {
        verify_token: this.getAdminToken(),
        video_id_list: removeVideoID,
      })

      this.$func.log('--- remove vimeo video success ---')
      this.$func.log(removeVimeoVideo)
    }

    this.$store.dispatch('resetRemovedVimeoVideoID', [])
  },
}
</script>