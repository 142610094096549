<template>
  <v-card rounded="lg" class="pa-5">
    <v-card-subtitle class="d-flex align-center">
      <div class="page-title mr-5">課程教材</div>
      <v-btn color="primary" class="px-6" depressed small :disabled="isFetching" @click="addTextBook">新增</v-btn>
    </v-card-subtitle>

    <v-card-text class="my-5">
      <v-row>
        <DraggableTable :tableHeader="headers" :data="dataList" @setOrder="setOrder" @dragTableAction="performAction">
          <template v-slot:[`textbookName`]="{ index }">
            <v-text-field
              class="my-3"
              color="indigo darken-4"
              persistent-placeholder
              v-model="dataList[index].name"
              hide-details="auto"
              outlined
              :required="true"
              :rules="[
                v => {
                  return $validate.required(v) || '此位置不能留空'
                },
              ]"
              :disabled="isFetching"
              :dense="true"
            />
          </template>

          <template v-slot:[`textbookFile`]="{ index }">
            <FormButtonUpload
              :uploadFile.sync="dataList[index].file"
              :fileInputKey="`textbookUpload_${index}`"
              :acceptFormat="'textBook'"
              :maxFileSize="100"
            ></FormButtonUpload>
          </template>
        </DraggableTable>
      </v-row>

      <div class="error-msg mt-5">{{ errorMsg }}</div>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import FormControl from '@/components/form/FormControl.vue'
import DraggableTable from './DraggableTable.vue'
import FormButtonUpload from './form/FormButtonUpload.vue'

export default {
  name: 'TextBookTable',
  components: { DraggableTable, FormControl, FormButtonUpload },
  props: {
    bookList: {
      type: Array,
      default: () => [],
      require: true,
    },
    errorMsg: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      headers: [
        { text: '標題', value: 'slot', slotName: 'textbookName', width: '35%', minWidth: '200px' },
        { text: '檔案', value: 'slot', slotName: 'textbookFile', width: '45%', minWidth: '300px' },
        { text: '', value: 'deleteBtn', width: '10%' },
      ],
      dataList: [],
    }
  },
  computed: {
    ...mapState({
      isFetching: state => state.request.isFetching,
    }),
  },
  methods: {
    ...mapActions(['setFetching'], 'request'),
    addTextBook() {
      this.dataList.push({
        name: '',
        file: '',
      })

      this.$emit('update:bookList', this.dataList)
    },
    setOrder(data) {
      this.$emit('update:bookList', data)
    },
    performAction(type, index) {
      if (type === 'delete') {
        if (this.$validate.DataValid(this.dataList[index].file)) {
          this.$emit('getRemovedTextBook', this.dataList[index].file)
        }

        this.dataList.splice(index, 1)
        this.$emit('update:bookList', this.dataList)
      }
    },
  },
  watch: {
    bookList: {
      handler(val) {
        this.dataList = val
      },
      deep: true,
    },
  },
}
</script>
