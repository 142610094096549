<script>
import * as tus from 'tus-js-client'

export default {
  name: 'VimeoMixin',
  methods: {
    createVimeoVideoLink(fileObj) {
      return new Promise((resolve, reject) => {
        let postData = {
          cms_create_vimeo_video_link: JSON.stringify({
            verify_token: this.getAdminToken(),
          }),
          file: fileObj,
        }

        this.$XHR
          .post(postData)
          .then(result => {
            this.$func.log('--- create vimeo link success ---')
            this.$func.log(result)

            resolve(result)
          })
          .catch(error => {
            this.$func.log('--- create vimeo link fail ---')
            this.$func.log(error)

            reject(error)
          })
      })
    },
/*     generateAddVideoVersionLink(fileObj, videoID) {
      return new Promise((resolve, reject) => {
        let postData = {
          add_vimeo_video_version: JSON.stringify({
            verify_token: this.getAdminToken(),
            video_id: videoID,
          }),
          file: fileObj,
        }

        this.$XHR
          .post(postData)
          .then(result => {
            this.$func.log('--- add vimeo video version link generate success ---')
            this.$func.log(result)

            resolve(result)
          })
          .catch(error => {
            this.$func.log('--- add vimeo video version link generate fail ---')
            this.$func.log(error)

            reject(error)
          })
      })
    }, */
    uploadVimeoVideo(fileObj, uploadUrl) {
      return new Promise((resolve, reject) => {
        if (!this.$validate.DataValid(uploadUrl)) {
          reject({ result: 'fail', data: 'upload url not found' })
          return
        }

        var upload = new tus.Upload(fileObj, {
          uploadUrl: uploadUrl,
          onError: error => {
            this.$store.dispatch('controlUploadAlert')
            this.$func.log('--- upload vimeo video fail ---')
            this.$func.log(error)
            reject(error)
          },
          onProgress: (bytesUploaded, bytesTotal) => {
            var percentage = ((bytesUploaded / bytesTotal) * 100).toFixed(2)
            this.$func.log(`${bytesUploaded}, ${bytesTotal}, ${percentage + '%'}`)

            this.$store.dispatch('controlUploadAlert', {
              show: true,
              progress: percentage + '%',
            })
          },
          onSuccess: () => {
            this.$store.dispatch('controlUploadAlert')
            this.$func.log('--- upload vimeo video success ---')
            this.$func.log(upload)
            resolve({ name: upload.file.name, url: upload.url })
          },
        })

        upload.start()
      })
    }
  },
}
</script>
